<template>
  <gmap-polyline :path="myPath" :options="options" />
</template>

<script>
import { $decodePath } from '../helper';

export default {
  computed: {
    options() {
      return { strokeColor: this.color, strokeWeight: this.weight };
    },
    myPath() {
      if (this.encodedPath) return $decodePath(this.encodedPath);
      if (this.path) return this.path;
      return [];
    }
  },
  props: {
    color: { type: String, default: '#026C75' },
    encodedPath: { type: String, default: '' },
    path: { type: Array, default: () => [] },
    weight: { type: [String, Number], default: '3' }
  }
};
</script>
